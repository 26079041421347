import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getToken() {
    var userJson = localStorage.getItem("currentUser");
    var user = JSON.parse(userJson);
    return user.basicAuthToken;
  }

  get(bookingDate) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getToken()}`
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "report/get?bookingDate=" + moment(bookingDate).format('YYYY/MM/DD'), { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }
}
