import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AuthService {
  currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    var user = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser.next(user);
  }


  public isAdmin(): boolean {
    var userJson = localStorage.getItem("currentUser");
    if (userJson == null)
      return false;

    var user = JSON.parse(userJson);
    return user.role == 'Admin';
  }

  isAuthenticated(): boolean {
    var user = localStorage.getItem("currentUser");
    if (user == null)
      return false;

    return true;
  }

  logout() {
    localStorage.removeItem("currentUser");
    this.currentUser.next(null);
  }

  register(email: string, password: string, vehicleReg: string) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      email: email,
      password: password,
      vehicleReg: vehicleReg.toUpperCase()
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "auth/register", dto, { headers: headers }).subscribe(data => {
        localStorage.setItem("currentUser", JSON.stringify(data));
        localStorage.setItem("@user", JSON.stringify(dto));
        
        this.currentUser.next(data);

        resolve(data);
      }, (error) => {
        resolve(error)
      });
    });
  }

  login(username: string, password: string) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      username: username,
      password: password
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "auth/post", dto, { headers: headers }).subscribe(data => {
        localStorage.setItem("currentUser", JSON.stringify(data));
        localStorage.setItem("@user", JSON.stringify(dto));
        
        this.currentUser.next(data);

        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
}