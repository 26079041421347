import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PackagesService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  get() : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "package/get", { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  getServices() : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "package/getservices", { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  getWaxTreatments() : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "package/getwaxtreatments", { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  getById(packageId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "package/getbyid?id=" + packageId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }
}
