import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  private async get(key: string) {
    return await this._storage?.get(key);
  }

  // Create and expose methods that users of this service can
  // call, for example:
  private set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  private async remove(key) {
    await this._storage?.remove(key);
  }

  public async getBookings() {
    var bookingsJson = await this.get('bookings') || '[]';
    return JSON.parse(bookingsJson);
  }

  public async saveBooking(booking) {
    var bookings = await this.getBookings();
    bookings.push(booking);
    
    this.set('bookings', JSON.stringify(bookings));
  }
}

