import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  get(startDate: moment.Moment, endDate: moment.Moment) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "booking/getbookingslots?startDate=" + startDate.format('YYYY/MM/DD') + "&endDate=" + endDate.format('YYYY/MM/DD'), { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  getPaymentIntent(bookingId: string) : Promise<string> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "checkout/createpaymentintent?bookingId=" + bookingId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  save(dto) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "booking/post", dto, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }
}
