import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { DpDatePickerModule } from 'ng2-date-picker';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PackagesService } from './services/packages.service';
import { ServicesService } from './services/services.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { BookingService } from './services/booking.service';

import { NgxStripeModule } from 'ngx-stripe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import { DatabaseService } from './services/database.service';
import { ReportService } from './services/report.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, 
    FormsModule, ReactiveFormsModule,
    DpDatePickerModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatabaseService,
    AuthService,
    PackagesService,
    ServicesService,
    BookingService,
    ReportService,

    {provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
